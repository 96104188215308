import { Disclosure, Transition } from '@headlessui/react'

import MarkdownText from 'components/atoms/markdown'

function FAQQuestion({ question: questionProp }) {
    const { question, answer } = questionProp || {};
    return (
        <div className="flex flex-col" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-white p-6 flex flex-row items-center justify-between w-full">
                            <span className="font-bold" itemProp="name">{question}</span>
                            <span className='text-2xl' aria-hidden="true">{open ? '-' : '+'}</span>
                        </Disclosure.Button>
                        <Transition
                            unmount={false}
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-100 ease-in"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel className="bg-white p-6 w-full">
                                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                    <div itemProp="text">
                                        <MarkdownText>{answer}</MarkdownText>
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </div>
    );
}

export default function FAQSection({ heading, questions }) {
    return (
        <section id="faqs" className="bg-teal-500 py-10" itemScope itemType="https://schema.org/FAQPage">
            <div className="max-w-7xl mx-auto flex flex-col items-stretch gap-6 px-4">
                <div className="text-center">
                    <h2 className="text-2xl font-normal text-blue-900">{heading}</h2>
                </div>
                <div className="grid md:grid-cols-2 gap-6">
                    {questions.map((question, idx) => (
                        <FAQQuestion key={idx} question={question} />
                    ))}
                </div>
            </div>
        </section>
    );
}
